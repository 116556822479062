import { list } from './../api/appUpgrade';
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

/**
 * 配置路由
 */
Vue.use(VueRouter)


/**
 * 路由地址
 */
import Layout from '@/views/layout/index.vue'
import Login from '@/views/login/index.vue'
import NotFound from '@/views/404/index.vue'
import Empty from '@/views/empty/index.vue'


/**
 * 路由输出
 * 路由path为固定的，需要与当前登录的用户所获得的菜单权限进行匹配，以path为匹配的标准，如果与路由的path不符合则跳转到empty界面
 */
export const routes: Array<RouteConfig> = [
  {
    path: "*",
    redirect: "/404"
  },
  {
    path: '/404',
    name: '404',
    component: NotFound
  },
  {
    path: '/',
    redirect: "/Layout"
  },
  {
    path: '/empty',
    name: 'empty',
    component: Empty
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/layout',
    component: Layout,
    children: [
      {
        path: '/',
        redirect: '/home'
      },
      {
        path: '/home',
        name: '首页',
        component: () => import('@/views/home/index.vue'),
      },
      {
        path: '/storeList',
        name: '门店信息',
        component: () => import('@/views/store-list/index.vue'),
      },
      {
        path: '/storeFill',
        name: '新增\编辑门店信息',
        component: () => import('@/views/store-list/fill.vue'),
      },
      {
        path: '/userMember',
        name: '用户管理',
        component: () => import('@/views/user-member/index.vue'),
      },
      {
        path: '/productList',
        name: '产品列表',
        component: () => import('@/views/product-list/index.vue'),
      },
      {
        path: '/productUse',
        name: '产品消耗',
        component: () => import('@/views/product-use/index.vue'),
      },
      {
        path: '/device',
        name: '设备管理',
        component: () => import('@/views/device/index.vue'),
      },
      {
        path: '/deviceType',
        name: '设备类型',
        component: () => import('@/views/device-type/index.vue'),
      },
      {
        path: '/qiaoDevice',
        name: '脉诊设备',
        component: () => import('@/views/qiaoDevice/index.vue'),
      },
      {
        path: '/appCompose',
        name: 'app排版',
        component: () => import('@/views/app-compose/index.vue'),
      },
      {
        path: '/merchantAppCompose',
        name: '品牌方app排版',
        component: () => import('@/views/app-compose/index.vue'),
      },
      {
        path: '/appUpgrade',
        name: 'app升级',
        component: () => import('@/views/app-upgrade/index.vue'),
      },
      
      {
        path: '/admin',
        name: '管理员',
        component: () => import('@/views/admin/index.vue'),
      },
      {
        path: '/dataDictionary',
        name: '数据字典',
        component: () => import('@/views/data-dictionary/index.vue'),
      },
      {
        path: '/user',
        name: '用户列表',
        component: () => import('@/views/user/index.vue'),
      },
      {
        path: '/menu',
        name: '菜单列表',
        component: () => import('@/views/menu/index.vue'),
      },
      {
        path: '/resource',
        name: '资源列表',
        component: () => import('@/views/resource/index.vue'),
      },
      {
        path: '/resourceCategory',
        name: '资源分类',
        component: () => import('@/views/resource-category/index.vue'),
      },
      {
        path: '/tongueList',
        name: '诊断次数',
        component: () => import('@/views/tongue-list/index.vue'),
      },   {
        path: '/tongueSug',
        name: '舌诊管理',
        component: () => import('@/views/tongue-sug/index.vue'),
      },
      {
        path: '/channelSignage',
        name: '服务商看板数据',
        component: () => import('@/views/service-provider/index.vue'),
      },
      {
        path: '/lungList',
        name: '肺功能管理',
        component: () => import('@/views/lung-list/index.vue'),
      },
      {
        path: '/productCategory',
        name: '产品分类管理',
        component: () => import('@/views/product-category/index.vue'),
      },
      {
        path: '/prescriptionDb',
        name: '处方库',
        component: () => import('@/views/prescription-db/index.vue'),
      },
      {
        path: '/miniManage',
        name: '小程序排版',
        component: () => import('@/views/appManage/mini.vue'),
      },
      {
        path: '/buildStoreManage',
        name: '建店配置',
        component: () => import('@/views/buildStores/index.vue'),
      },
      {
        path: '/buildStoreList',
        name: '建店列表',
        component: () => import('@/views/buildStores/list.vue'),
      },
      {
        path: '/moxibustionTemplate',
        name: '云端模版',
        component: () => import('@/views/moxibustion/template.vue'),
      },
      {
        path: '/moxibustionOrder',
        name: '订单列表',
        component: () => import('@/views/moxibustion/order.vue'),
      },
      {
        path: '/merchantList',
        name: '品牌方',
        component: () => import('@/views/prescription-db/brand.vue'),
      },
      {
        path: '/merchantDb',
        name: '品牌方处方库',
        component: () => import('@/views/prescription-db/merchant.vue'),
      },
      {
        path: '/physique',
        name: '体质辨识症状',
        component: () => import('@/views/physique/index.vue'),
      },
      {
        path: '/merchantProductCategory',
        name: '品牌方产品分类',
        component: () => import('@/views/product-category/merchant.vue'),
      },
      {
        path: '/storeActivity',
        name: '门店活动',
        component: () => import('@/views/storeActivity/index.vue'),
      },
      {
        path: '/booking',
        name: '门店预约',
        component: () => import('@/views/booking/index.vue'),
      },
      {
        path: '/bookingData',
        name: '预约看板',
        component: () => import('@/views/booking/data.vue'),
      },
      {
        path: '/userDetectionRecord',
        name: '用户检测次数',
        component: () => import('@/views/user-detection-record/index.vue'),
      },
      {
        path: '/healthEvaluationList',
        name: '健康测评',
        component: () => import('@/views/healthEvaluation/index.vue'),
      },
      {
        path: '/healthEvaluationFill',
        name: '试卷配置',
        component: () => import('@/views/healthEvaluation/fill.vue'),
      },
      {
        path: '/userDetectionList',
        name: '客户检测记录',
        component: () => import('@/views/user-detection-record/detection.vue'),
      },
      {
        path: '/userRecuperateList',
        name: '客户调理记录',
        component: () => import('@/views/user-detection-record/recuperate.vue'),
      },
      {
        path: '/storeData',
        name: '门店数据',
        component: () => import('@/views/data/store.vue'),
      },
      {
        path: '/restSetting',
        name: '门店休息',
        component: () => import('@/views/store-list/restSetting.vue'),
      },
      {
        path: '/appDescriptionSetting',
        name: 'APP介绍说明',
        component: () => import('@/views/appManage/description.vue'),
      },
      {
        path: '/saasServiceSetting',
        name: 'SAAS服务配置',
        component: () => import('@/views/health/saasService/index.vue'),
      },
      {
        path: '/tongueSetting',
        name: '舌诊管理',
        component: () => import('@/views/tongue/index.vue'),
      },
      {
        path: '/period',
        name: '门店分期',
        component: () => import('@/views/period/index.vue'),
      },
      {
        path: '/watchIndex',
        name: '健康数据',
        component: () => import('@/views/watch/index.vue'),
      },
      {
        path: '/healthRecord',
        name: '健康档案',
        component: () => import('@/views/healthRecord/index.vue'),
      },
      {
        path: '/healthRecordDetail',
        name: '分组详情',
        component: () => import('@/views/healthRecord/detail.vue'),
      },
      {
        path: '/symptoms',
        name: '百症调养',
        component: () => import('@/views/symptoms/index.vue'),
      },
      {
        path: '/selectRecommendation',
        name: '精选推荐',
        component: () => import('@/views/selectRecommendation/index.vue'),
      },
      {
        path: '/prescription-product-category',
        name: '产品分类配置',
        component: () => import('@/views/prescriptionProduct/category.vue'),
      },
      {
        path: '/prescription-product-list',
        name: '产品分类配置',
        component: () => import('@/views/prescriptionProduct/product.vue'),
      },
      {
        path: '/prescription-product-merchant-category',
        name: '品牌方产品分类配置',
        component: () => import('@/views/prescriptionProduct/merchantCategory.vue'),
      },
      {
        path: '/prescription-product-merchant-list',
        name: '品牌方产品列表',
        component: () => import('@/views/prescriptionProduct/merchantProduct.vue'),
      },

      
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
// README: 百度埋点，这个最好放在 router 文件里面，防止beforeEach 后续被外部文件覆盖
// @ts-ignore
const _hmt = window._hmt || [];
router.beforeEach(
  (to,from,next)=>{
      if(to.path){
          if(_hmt){
             _hmt.push(['_trackPageview','/#'+to.fullPath]);
          }
      }
      next();
  }
);

export default router
